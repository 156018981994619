import { Decimal } from 'decimal.js'

export const YEARS_OF_ANALYSIS = [
  {
    label: '2030',
    isChecked: true,
  },
  {
    label: '2040',
    isChecked: false,
  },
  {
    label: 'Net Zero',
    isChecked: false,
  },
]

const LITHIUM = {
  label: 'Lithium',
  isChecked: true,
  color: '#20267C',
  values: {
    2030: 94.0672570100148,
    2040: 187.901432412271,
    'Net Zero': 404.301249738226,
  },
}

const BATTERY_CELLS = {
  label: 'Battery Cell',
  isChecked: true,
  color: '#FDBD00',
  values: {
    2030: 222.064302581064,
    2040: 685.683110582951,
    'Net Zero': 1330.33350518747,
  },
}

export const ALL_MATERIALS = {
  rawMaterials: {
    mainColor: '#92B5D5',
    items: [
      {
        label: 'Nickel',
        isChecked: true,
        color: '#1C3E96',
        values: {
          2030: 56.0188411606699,
          2040: 119.405701646608,
          'Net Zero': 217.7891423835,
        },
      },
      { ...LITHIUM },
      {
        label: 'Cobalt',
        isChecked: true,
        color: '#2F76B2',
        values: {
          2030: 10.6898083717615,
          2040: 12.7024909216367,
          'Net Zero': 31.5692768627725,
        },
      },
      {
        label: 'Graphite',
        isChecked: true,
        color: '#2F76B2',
        values: {
          2030: 4.23502817526259,
          2040: 9.62215980965414,
          'Net Zero': 39.5289718903092,
        },
      },
    ],
  },
  others: {
    mainColor: '#707070',
    items: [
      {
        label: 'Other Materials',
        isChecked: true,
        color: '#707070',
        values: {
          2030: 119.617553679561,
          2040: 359.845426414671,
          'Net Zero': 631.876619622461,
        },
      },
    ],
  },
  batteryMaterials: {
    mainColor: '#E96600',
    items: [
      {
        label: 'Cathode',
        isChecked: true,
        color: '#EB6600',
        values: {
          2030: 46.454174052949,
          2040: 157.181772971633,
          'Net Zero': 335.820343062903,
        },
      },
      {
        label: 'Anode',
        isChecked: true,
        color: '#7A2F12',
        values: {
          2030: 13.5945234400713,
          2040: 43.2844892841942,
          'Net Zero': 85.4552455500067,
        },
      },
    ],
  },
  batteries: {
    mainColor: '#FBBA00',
    items: [{ ...BATTERY_CELLS }],
  },
}

// get all items from all categories
const allItems = Object.values(ALL_MATERIALS).reduce((items, current) => {
  return [...items, ...current.items]
}, [])

const totalIn2030 = allItems.reduce((total, current) => {
  return total + current.values[2030]
}, 0)

export const ALL_MATERIALS_TOTALS = {
  id: 0,
  mineral: 'All Materials',
  value: `${new Decimal(totalIn2030).toFixed(2)} billion`,
  imageUrl:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,h_320/v1690298599/assets/home/all-minerals-image_yvirfn.png',
}

export const capexTrackerData = [
  ALL_MATERIALS_TOTALS,
  {
    id: 1,
    mineral: 'Lithium',
    value: `${new Decimal(LITHIUM.values['2030']).toFixed(2)} billion`,
    imageUrl:
      'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,q_auto,h_320/v1692224705/assets/home/lithium-image_ljdye9.png',
  },
  {
    id: 2,
    mineral: 'Batteries',
    value: `${new Decimal(BATTERY_CELLS.values['2030']).toFixed(2)} billion`,
    imageUrl:
      'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,h_320/v1690298612/assets/home/batteries-image_jlnbbu.png',
  },
]
